.App {
  text-align: center;
}

@font-face {
  font-family: Simple;
  src: url(./fonts/simple_thread/simpleThread.otf);
  font-display: fallback;
}

@font-face {
  font-family: Gotham;
  src: url(./fonts/Gotham-Light.otf);
  font-display: fallback;
}

#header-title{
  font-family: Simple;
  color: #a0914ee9;
  letter-spacing: 3px;
}

#head-img{
  background-image: url('./cloud.jpeg');
  height: 90vh;
  width: 100vw;
  background-position: center;
  background-size: cover;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}



.white h1{
  color: #3b4a69;
}

h1{
  font-size: 2rem;
  letter-spacing: 2px;
}

*{
  box-sizing: border-box;
  font-family: Gotham ;
}

html{
  scroll-behavior: smooth;
}

nav{
  height: 75px;
  width: 100vw;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 1rem;
  position: fixed;
  z-index: 999;
}

#about *{
  color: #1e1e1e;
}
.white{
  /*background-color: #edeae4;*/
  background-color: #fafafa;

}

#nav-logo{
  
  opacity: 0.8;
  max-height: 35%;
  border-radius: 3px;
  z-index: 3;
}

a{
  color: white;
  text-transform: uppercase;
  font-size: 10px;
  font-weight: bold;
  margin: 0 0.7rem;
  text-decoration: none;
}

a:hover{
  text-decoration: underline;
}

#cloud{
  width: 100vw;
  height: 80vh;
  object-position: center;
  object-fit: cover;
}

.divider{
  height: 7px;
  background-color: #a99c65;
  margin: 0;

}

.img{
  height: 300px;
  box-shadow: rgba(0, 0, 0, 0.25) 0px 14px 28px, rgba(0, 0, 0, 0.22) 0px 10px 10px;
  max-width: 100vw;
}

.flexwrap-container{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
}

main{
  min-height: 100vh;
}

.blue{
  background-color: #3b4a69;
  width: 100vw;
  display: flex;
  padding: 1rem 0;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
}


.txt{
  width: 800px;
  padding: 2rem;
  font-size: 1.1rem;
  line-height: 25px;
  text-align: left;
}

.white h1, .blue h1{
font-size: 1.1rem;
font-weight: bold;
text-transform: uppercase;
}

.social-icon{
  height: 30px;
  margin: 0 0.5rem;
}



footer{
  height: 150px;
  background-color: #3b4a69;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  padding: 2rem;
  align-items: center;
  color: white;
}

#hero{
  position: relative;
  display: flex;
  flex: auto;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  background-image: url('./images/small-dog.png');
  background-position: 15% 100%;
  background-size: cover;
  width: 100vw;
  min-height: 95vh;
}

#btn{
  background-color: #7a9b96;
  color: white;
  border: unset;
  letter-spacing: 3px;
  border-radius: 5px;
  width: auto;
  height: auto;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 13px 27px -5px, rgba(0, 0, 0, 0.3) 0px 8px 16px -8px;  
  font-weight: lighter !important;
  font-size: 10px;
  font-family: Minion;
  padding: 10px;
  text-transform: uppercase;

}

#horse-vid{

  width: 100vw;
  height: auto;
  min-height: 95vh;
  z-index: 0;
  object-fit: cover;
  object-position: 15% 100%;
}

#gradient{
  background: rgba(237, 234, 228, 0.5);
  background: linear-gradient(to bottom, rgba(250, 250, 250, 0.5) 0%, rgba(250, 250, 250, 0.8) 90%, rgba(250, 250, 250, 1) 100%);  
  width: 100%;
  height: 100%;
  z-index: 2;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}



a{
  margin: 0 10px;
  font-size: 12px;
  color: #282c34;
  text-decoration: none;
  font-weight: 900
}

#hero-logo{
  width: 300px;
  max-width: 60vw;
}


@media only screen and (max-width: 600px) {
  #socials{
    display: none;
  }


  a{
    margin: 0.6rem;
    font-size: 0.6rem;

  }
  nav{
    justify-content: center;
    padding: 0;
  }
}
