form{
    flex-direction: column;
    position: relative;
    width: 500px;
    max-width: 100vw;
    margin: 1rem auto;
    padding: 1rem;
}

#contact{
    display: flex;
    flex-direction: column;
    padding: 2rem 0;
}

input, select{
    width: 100%;
    height: 40px;
    margin: 0.3rem auto;
    border: 1px solid #1e1e1e;
    border-radius: 3px;
    font-size: 16px;
    padding: 10px;
}

textarea{
    margin: 0.3rem auto;
    height: 200px;
    resize: none;
    width: 100%;
    padding: 10px;
    font-size: 16px;
    font-family: Arial, Helvetica, sans-serif;
}

#submit-btn{
    width: 150px;
    height: 40px;
    background-color: #3b4a69;
    color: white;
    font-weight: bold;
    padding: 5px;
    margin: 0.3rem auto ;
    border: none;
    border-radius: 3px;
}

#consent{
    font-size: 12px; 
    width: 500px; 
    max-width: 90vw;
    margin: 0 auto; 
    text-align: left;
}